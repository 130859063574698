import { useCallback, useRef, useEffect } from 'react'

export default function useLampDragUp(lampSectionRef, onComplete) {
  const isDragging = useRef(false);
  const startY = useRef(0);
  const currentY = useRef(0);

  const getCursorPos = useCallback((e) => {
    let y = 0;
    e = e.changedTouches ? e.changedTouches[0] : e;
    y = e.pageY;
    return y;
  }, []);

  const handleDragMove = useCallback((e) => {
    if (!isDragging.current || !lampSectionRef.current) return;

    const y = getCursorPos(e);
    const deltaY = startY.current - y;
    currentY.current = deltaY;

    // Calculate opacity based on drag distance
    const maxDrag = window.innerHeight * 0.5; // 50% of viewport height
    const opacity = Math.max(0, 1 - (deltaY / maxDrag));
    
    // Calculate transform based on drag distance
    const transform = Math.min(deltaY, maxDrag);
    
    lampSectionRef.current.style.transform = `translateY(-${transform}px)`;
    lampSectionRef.current.style.opacity = opacity;

    // If dragged more than 50% of the screen height, trigger exit
    if (deltaY > maxDrag) {
      isDragging.current = false;
      lampSectionRef.current.style.transform = 'translateY(-100%)';
      lampSectionRef.current.style.opacity = '0';
      
      // Call the callback function instead of dispatching an event
      onComplete();
    }
  }, [getCursorPos, onComplete]);

  const handleDragStart = useCallback((e) => {
    // Check if the event target is part of the slider
    const target = e.target;
    if (target.closest('.wrapper') || target.closest('button')) {
      return;
    }

    e.preventDefault();
    isDragging.current = true;
    startY.current = getCursorPos(e);
  }, [getCursorPos]);

  const handleDragEnd = useCallback(() => {
    if (!isDragging.current || !lampSectionRef.current) return;
    isDragging.current = false;

    // If not dragged enough, snap back to original position
    const maxDrag = window.innerHeight * 0.5;
    if (currentY.current < maxDrag) {
      lampSectionRef.current.style.transform = 'translateY(0)';
      lampSectionRef.current.style.opacity = '1';
    }
  }, []);

  useEffect(() => {
    if (!lampSectionRef.current) return;

    const element = lampSectionRef.current;

    // Add touch and mouse event listeners
    element.addEventListener('mousedown', handleDragStart);
    element.addEventListener('touchstart', handleDragStart);

    window.addEventListener('mousemove', handleDragMove);
    window.addEventListener('touchmove', handleDragMove);

    window.addEventListener('mouseup', handleDragEnd);
    window.addEventListener('touchend', handleDragEnd);

    return () => {
      element.removeEventListener('mousedown', handleDragStart);
      element.removeEventListener('touchstart', handleDragStart);

      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('touchmove', handleDragMove);

      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [handleDragStart, handleDragMove, handleDragEnd]);
}
